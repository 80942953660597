@import './languageSelectorComp';

body {
  margin: 0;

  &.#{$channel-google} {
    div {
      &##{$root-main} {
        [data-id*='#{$header-sign-in}'] {
          display: none;
        }
      }
    }
  }

  &.#{$channel-google},
  &.#{$channel-phone-pe} {
    div {
      &##{$root-main} {
        [data-id*='#{$checkout-sign-in}'] {
          display: none;
        }
        [data-id*='#{$checkout-help-icon}'] {
          margin-left: auto;
        }
      }
    }
  }

  &.#{$channel-debug} {
    &.#{$channel-google},
    &.#{$channel-phone-pe},
    &.#{$channel-pay-tm} {
      div {
        &##{$root-main} {
          [data-id*='#{$header-sign-in}'] {
            display: flex;
          }
        }
      }
    }
  }
}

.item-unavailable.store-time-msg .modal-content .alert-content {
  width: 64%;
}

#header-container {
  z-index: $z-index-max;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: white;
}

#header-container-hidden {
  z-index: $z-index-max;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: white;
  @media #{$breakpoint-below-tablet} {
    display: none;
  }
}
.root-header {
  &.au {
    @media #{$breakpoint-ipad-12-pro-portrait} {
      padding-left: 1.2rem !important;
      padding-right: 1.2rem !important;
    }
    & .root-header-view {
      //border-bottom: 1px solid rgb(219, 219, 219);
      overflow-x: initial;
      .navigation-link {
        text-decoration-line: none;
      }

      & .header-item-buttons {
        &.header-hamburger-icon {
          padding: 0;
          border: none;
          outline: none;
          background: transparent;
          margin: 0 19px 0 20px;
          height: 45px;
          @media #{$mobile-only} {
            margin: -2px 19px 0 22px;
          }
          @media #{$breakpoint-above-tablet} {
            display: none;
          }

          img {
            vertical-align: middle;
          }
        }
      }

      .header-logo-mobile {
        //margin: auto 2.8% auto 9%;
        margin: auto 2.8% auto 0;
        display: inline-block;
        cursor: pointer;
        @media #{$mobile-only} {
          margin: 2% auto !important;
        }
        img {
          width: 72px;
          height: 23px;
          @media #{$mobile-only} {
            width: 57px;
            height: 18px;
          }
        }
        @media #{$breakpoint-below-tablet} {
          margin: auto;
        }
      }
      .header-logo-mobile-cart {
        @media #{$breakpoint-medium-desktop} {
          margin: auto 2.8% auto 3.5%;
        }
        @media #{$breakpoint-ipad-pro-12-landscape} {
          margin: auto 2.8% auto 1.5%;
        }
      }

      @import './menuLinksComp';

      .menu-links-container {
        @media #{$breakpoint-below-tablet} {
          display: none;
        }
      }

      .right-nav-container {
        height: 42px;
        //margin: 29px 6.5% 29px auto;
        margin: 29px 0 29px auto;
        display: flex;
        width: 17%;

        @media #{$breakpoint-above-tablet} {
          min-width: 245px;
        }

        @media #{$breakpoint-below-tablet} {
          height: 38px;
          width: 61px;
          margin: 3px 0px 3px 0px;
        }

        @include language-selector-comp-styles($withBorder: false, $color: $Brand-Black);

        .sign-in-container {
          margin: auto 0;
          border-right: 1px solid $Light-Gray;
          display: flex;

          .account-container {
            margin: -3px 5px 0 13px;
            cursor: pointer;
            min-width: 105px;
            width: auto;
            line-height: 24px;
            position: relative;

            .msg-badge {
              position: absolute;
              top: -7px;
              left: 9px;
              background: red;
              display: flex;
              border-radius: 20px;
              color: #fff;
              font-size: 10px;
              height: 18px;
              min-width: 18px;
              justify-content: center;
              align-items: center;
            }
            .account-icon {
              width: 18px;
              height: 18px;
              margin-top: -4px;
            }
            .sign-in-text {
              font-family: $National2Medium;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              margin-left: 14px;
              align-items: center;
              text-align: right;
              color: $Brand-Black;
              width: fit-content;

              &--jp {
                font-size: 18px;
                font-weight: 700;
              }
            }
            .arrow {
              display: none;
            }
          }
          @media #{$breakpoint-below-tablet} {
            display: none;
          }
        }

        @import './cartIconComp';
      }
    }
    .root-header-view-policies {
      @media #{$breakpoint-above-tablet} {
        margin-left: 130px;
        margin-right: 104px;
        max-width: 100%;
      }
    }

    .support-links-menu {
      padding-right: 40%;
    }
  }
}
// ad-banner for mobile mode
.kfc-banner-image {
  & .banner-image {
    position: relative;
    width: 100%;
  }
  & .close-button {
    display: block;
    position: absolute !important;
    margin-top: 6%;
    left: 3%;
    z-index: 99;
  }
  @media (min-width: 650px) {
    display: none;
  }
}

.signMeUpBanner-container {
  background: #e4002b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: auto;
  @media #{$mobile-only} {
    height: auto !important;
  }
  @media #{$breakpoint-below-tablet} {
    height: 578px;
  }
  .signMeUpBanner-heading {
    color: #ffffff;
  }
  .signMeUpBanner-stripes-icon {
    .signMeUpBanner-stripes-img {
      width: 56px;
      height: 34px;
      margin-bottom: 2.8rem;
      margin-top: -3px;
      @media #{$breakpoint-below-tablet} {
        height: 20px;
        margin-bottom: 2.5rem;
        margin-top: 0;
      }
      @media #{$mobile-only} {
        width: auto;
        margin-top: 0;
      }
    }
  }
  .headline-container {
    width: 580px;
    height: 20px;
    position: relative;
    bottom: 10px;
    .creat-account {
      margin-top: 2px;
      margin-bottom: 10px;
      font-family: $National2Condensed;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */

      text-align: center;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    @media #{$breakpoint-below-tablet} {
      width: 335px;
      margin-bottom: 1rem;
      margin-top: 0px;
    }
    @media #{$mobile-only} {
      margin-bottom: 20px;
      height: 20px;
      margin-top: 0px;
    }
  }
  .subHeadline {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 68px;
    /* or 106% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #ffffff;
    max-width: 590px;
    margin-bottom: 0.65rem;
    @media #{$breakpoint-below-tablet} {
      font-size: 36px;
      line-height: 36px;
      letter-spacing: unset;
      height: 72px;
      width: 350px;
      left: 20px;
      top: 100px;
    }
    @media #{$breakpoint-iphone-se-2020} {
      width: 350px;
    }
    @media #{$mobile-only} {
      margin-bottom: 20px;
      letter-spacing: 1px;
    }
  }
  .signMeUpBanner-heading-container {
    width: 350px;
    @media #{$breakpoint-below-tablet} {
      height: 57px;
      width: 192px;
    }
    @media #{$breakpoint-samsung-s21plus} {
      width: 210px;
    }
    @media #{$breakpoint-samsung-s20} {
      width: 210px;
    }
    @media #{$breakpoint-s21plus-samsung} {
      width: 210px;
    }
    @media #{$breakpoint-samsung-galaxy-tab-s7} {
      @media (orientation: portrait) {
        width: 214px;
      }
    }
    @media #{$breakpoint-samsung-galaxy-tab-s7-landscape} {
      @media (orientation: landscape) {
        width: 390px;
      }
    }
  }
  .subHeadline2 {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.1px;
    position: relative;
    top: 10px;
    @media #{$breakpoint-below-tablet} {
      font-size: 14px;
      line-height: 150%;
    }
    @media #{$mobile-only} {
      position: relative;
      top: 0;
    }
  }
  .button-contained {
    margin-bottom: 40px;
    margin-top: 32px;
    @media #{$mobile-only} {
      padding-left: 37px !important;
      padding-right: 37px !important;
    }
  }
}
.catering-menu-nav-alert {
  .modal-body {
    .alert-title {
      padding: 3% 11% !important;
      @media #{$breakpoint-between-tablet-desktop} {
        padding: 0% 10% !important;
      }
    }
    .alert-content {
      width: 90% !important;
      @media #{$mobile-only} {
        width: 85% !important;
      }
    }
  }
  .alert-action-container {
    @media #{$breakpoint-below-tablet} {
      margin-bottom: 20px !important;
    }
  }
}
